<template>
  <div>
    <CCard class="col-md-12">
      <CRow class="p-3">
        <h4 class="color-light ml-2 page_title">
          <span class="ul_text">CU</span>STOM DOMAIN
        </h4>
      </CRow>
      <CCardBody class="col-md-12">
        <CRow>
          <CCol>
            <p class="col-md-10" style="font-size: 12px">
            Host fraudavoid on your own domain! Make sure you create a CNAME DNS
            record for your domain to
            <span class="text-info"> {{ bsckHostUrl }} </span>. It can take up to
            24 hours for the custom domain to be activated.
          </p>
          </CCol>
        </CRow>
        <CRow>
          <CCol class="col-md-4 col-12">
            <CInput
              class="col-md-12"
              size="sm"
              v-model="custom_domain"
              required
              placeholder="Enter Domain"
            />
          </CCol>
          <CCol>
            <CButton
              size="sm"
              type="submit"
              v-on:click="onSubmit"
              color="success"
              >Add Domain</CButton
            >
          </CCol>
        </CRow>
        <CRow>
          <CCol class="col-md-12">
            <CDataTable class="col-md-12"
              :items="all_custom_domain"
              :fields="fields"
              :dark="false"
              pagination
              sorter
              hover
              striped
              border
              small
              fixed
              light
            >
        <template #domains="{ item }">
            <td>
              {{ item }}
            </td>
          </template>
          <template #action="{ item, index }">
            <td>
              <CButton
                size="sm"
                color="danger"
                @click="deleteDomain(item,index)"
                >Delete</CButton
              >
            </td>
          </template>
        </CDataTable>
          </CCol>
        </CRow>
      </CCardBody></CCard
    >
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import SettingService from "../../services/settingControlService";
import conf from "../../services/config";
export default {
  data() {
    return {
      custom_domain: "",
      all_custom_domain: [],
      bsckHostUrl: conf.backHost,
      fields: [
        { key: "domains", label: "Custom Domains List" },
        { key: "action", label: "Action" },
      ],
    };
  },
  async mounted() {
    if (
      window.localStorage.getItem("user") &&
      window.localStorage.getItem("status") != "true"
    ) {
      this.$router.push("/");
    } else {
      this.getCustomDomain();
    }
  },
  methods: {
   async deleteDomain(item,index){
      if(confirm("Are you sure you want to delete this domain?")){
        let domain = this.all_custom_domain.filter((i,k)=>k !== index)
        this.all_custom_domain = domain
        this.onSubmit();
      }
    },
    async getCustomDomain() {
      let response = await SettingService.getCustomDomain();
      if (response.message) {
        this.all_custom_domain = []
        this.all_custom_domain = response.data[0].customDomain;
      }
    },
    async onSubmit() {
      if(!this.custom_domain){
        Swal.fire({
          title: "Error!",
          text: "Please enter domain",
          icon: "error",
          confirmButtonText: "Close",
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
        });
        return;
      }
      let domains = [...this.all_custom_domain];
      if(this.custom_domain != ""){
        domains.push(this.custom_domain);
      }
      let domain_data = {
        customDomain: domains,
      };
      let response = await SettingService.addCustomDomain(domain_data);
      if (response.result == true) {
        this.custom_domain = "";
        this.getCustomDomain();
        Swal.fire({
          title: "Success!",
          text: response.message,
          icon: "success",
          confirmButtonText: "Close",
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          title: "Error!",
          text: response.message,
          icon: "error",
          confirmButtonText: "Close",
          position: "top-end",
          showConfirmButton: false,
          timer: 2000,
        });
      }
    },
  },
};
</script>
